
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/legal/[policyPageSlug]",
      function () {
        return require("private-next-pages/legal/[policyPageSlug].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/legal/[policyPageSlug]"])
      });
    }
  